import { createStore } from 'vuex'

export default createStore({
  state: {
    menu_data: [],
    acf: {},
    yoast: {},
  },
  getters: {
    get_acf: state => { return state.acf; },
    get_menu_data: state => { return state.menu_data; },
    get_yoast: state => { return state.yoast; },
  },
  mutations: {
    SET_ACF(state, val) { state.acf = val; },
    SET_MENU_DATA(state, val) { state.menu_data = val; },
    SET_YOAST(state, val) { state.yoast = val; },
  },
  actions: {
    set_acf({ commit }, val) { commit('SET_ACF', val); },
    set_menu_data({ commit }, val) { commit('SET_MENU_DATA', val); },
    set_yoast({ commit }, val) { commit('SET_YOAST', val); },
  }
})