import { createRouter, createWebHistory } from 'vue-router'

import axios from 'axios';

let response = await axios.get(process.env.VUE_APP_WORDPRESS_API_URL+"wp-json/wp/v2/pages?per_page=100&page=1");


// Get the URLSearchParams object from the current URL
const urlParams = new URLSearchParams(window.location.search);

// Create an object to store the parsed parameters
const params = {};

// Loop through each parameter and store it in the params object
for (const [key, value] of urlParams) {
  params[key] = value;
}

// Now you can access the parameters using the params object
console.log(params);

const routes = response.data.map(page => ({
    path: `/${page.slug}`,
    name: page.slug,
    meta:{
        seo: page.yoast_head_json,
        dataUrl: page._links.self[0].href.replace(process.env.VUE_APP_WORDPRESS_FRONTEND_URL, process.env.VUE_APP_WORDPRESS_API_URL)
    },
    component: () => import(`./components/DefaultPage.vue`),
    //props: { page }
}));

console.log('routes', routes);
// copy /home route to /
let home = {...routes.find(r => r.path === '/home')};
home.path = '/';
routes.push(home);


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active'
})

router.beforeEach((to, from, next) => {
    console.log('to', to)
    //document.title = process.env.VUE_APP_COMPANY_NAME;
    if (to.meta && to.meta.seo && to.meta.seo.og_title) {
        document.title = to.meta.seo.og_title;

    }
    next();


});

export default router
