import axios from 'axios';
export default {
    computed: {
        api_url() {
            return process.env.VUE_APP_WORDPRESS_API_URL;
        },
        template_url() {
            return process.env.VUE_APP_WORDPRESS_API_URL+process.env.VUE_APP_WORDPRESS_TEMPLATE_DIRECTORY;
        },
        acf() {
            return this.$store.getters.get_acf;
        },

    },
    methods: {
        updateHead() {
            let yoast = this.$store.getters.get_yoast;
            console.log('yoast', yoast)
            
            const head = document.head || document.getElementsByTagName('head')[0];
            const charsetMeta = head.querySelector('meta[charset="utf-8"]');
            
            // Als het charsetMeta-element niet wordt gevonden, stoppen we de functie
            if (!charsetMeta) {
              console.error('Charset meta tag not found');
              return;
            }
          
            // Maak een nieuw div element aan om de nieuwe inhoud tijdelijk op te slaan
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = yoast;
          
            // Verwijder alle elementen in de head vóór de charsetMeta
            let currentNode = head.firstChild;
            while (currentNode && currentNode !== charsetMeta) {
              const nextNode = currentNode.nextSibling;
              head.removeChild(currentNode);
              currentNode = nextNode;
            }
          
            // Voeg de nieuwe inhoud toe aan de head
            while (tempDiv.firstChild) {
              head.insertBefore(tempDiv.firstChild, charsetMeta);
            }
            
        },
        addPreviewParams(url) {
            //return url;
            let params = window.location.search
            if(!params) return url;

            if (url.indexOf('?')==-1) {
                return url.replace('?', params);
            }
            else {
                return url.replace('?', params+'&');
            }
        },
        async callApi(method, url, dataObject) {
            try {
                var res = await axios({
                    method: method,
                    url: url,
                    data: dataObject
                });
                
                if (res.status==419) {
                    window.location.href = '/';
                }
                
                if (res.data.error) {
                    //alert(res.data.error);
                    this.showErrorMessage('Fout', res.data.error);
                }

                if (res.data.log && res.data.log.debug && res.data.log.debug.length) {
                    console.table(res.data.log.debug)
                }
                

                return res;
            }
            catch(e) {
                console.log("CallApi ERROR", url,  e);
                // if (e.response.status==401) {
                //     // unauthorized
                //     this.$router.push('/login')
                //     return {data:false};
                // }
                // else {
                //     this.showTemporaryErrorMessage('Netwerkfout', 'Er is een fout opgetreden: '+e.message);
                //     //alert('Er is een fout opgetreden: '+e.message)
                // }
                
            }
        }
    }
    
    

}