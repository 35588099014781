

<template>
  

    


    <router-view
      :key="$route.fullPath"
      />
    
  
</template>


<script>

//import axios from 'axios';

//import { HeadlessProvider } from "@wpengine/headless";


export default {
  components: {
    //HeadlessProvider
  },
  data() {
 return {
      posts: [],
      pages: [],
    };
  },
  methods: {
    



  },
  created() {
      //this.getPages();
      //this.getData();
  },
};
</script>




<style>

</style>
