import { createApp } from 'vue'
import App from './App.vue'


import router from './router.js'
import store from './store.js'

import tools from './tools.js'

const app = createApp(App);

app.mixin(tools);

app.use(router);
app.use(store);

app.mount('#app')

